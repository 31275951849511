import jQuery from "jquery";
window.$ = jQuery;
window.jQuery = jQuery;
import "what-input";

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
require('foundation-sites');

require('slick-carousel');
require('magnific-popup');
require('@fortawesome/fontawesome-free');
require('flatpickr');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

Foundation.Abide.defaults['validators']['datestamp'] =
  function ($evt, required, parent) {
    var evt = $evt;
    return evt[0].value.match('[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}') ? true : false;
  };

document.addEventListener('DOMContentLoaded', () => {
  $(document).foundation();

  //Main navigation active parent menu link
  $(".active.is-submenu-item.is-dropdown-submenu-item").parent("ul").parent("li").addClass("active");

  if ($('.mfp-image').length) {
    $('.mfp-image').magnificPopup({
      type: 'image',
      closeMarkup: '<button title="Close (Esc)" type="button" class="mfp-close"><i class="far fa-times-circle"></i></button>'
    });
  }
  // Initialize Slick Carousel, with configurations
  if ($(".slick-slider").length) {
    $('.slick-slider').slick({
      //arrows: true,
      dots: true,
      cssEase: 'linear'
    });
  }

  //Init carousel inside of modal
  $('.modal-carousel').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.nav-carousel'
  });
  //Init nav carousel for modal carousel
  $('.nav-carousel').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.modal-carousel',
    dots: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });


  //Carousel inside of modal must resize on initial open
  //to load functionally
  $(".carousel-modal").on("click", function () {

    //When slick dot is clicked, the active image doesn't open.
    //Delay half a second before re-setting position to fix
    setTimeout(function () {
      $('.modal-carousel').slick('setPosition');
    }, 500)

    $(".modal-carousel").slick("resize");
  });

  $(".slick-dots button").on("click", function () {
    $(".modal-carousel").slick("resize");
  });

  //Magnific popup modal
  $('.carousel-modal').magnificPopup({
    //type:'inline',
    closeMarkup: '<button title="Close (Esc)" type="button" class="mfp-close"><i class="far fa-times-circle"></i></button>',
    midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
  });
  // Temp fix for magnific popup close issue
  $(document).on('click', '.mfp-close', function (e) {
    e.preventDefault();
    $.magnificPopup.close();
  });

  // Show/hide the edit reservation form
  $('.reservation-overview .read-more').on('click', function () {
    $('.reservation-edit').toggle();
  });

  //Table to Foundation columns transformation
  var rowMarkup = "";
  var wrapperMarkup = "";
  var markup = "";

  $('table.columns').each(function (index) {
    var table_classes = $(this).attr("class").split(' ');
    var border_class = '';
    for (var i = 0; i < table_classes.length; i++) {
      if (table_classes[i] == "border-desktop" ||
        table_classes[i] == "border-mobile" ||
        table_classes[i] == "border-desktop-mobile" ||
        table_classes[i] == "border-shadow") {
        border_class = table_classes[i];
      }
    }
    if ($(this).hasClass("table-grid")) {
      $(this).find('td').replaceWith(function () {
        markup = '<div class="cell">' + $(this).html() + '</div>';
        return markup;
      });
    } else if ($(this).hasClass("four")) {
      $(this).find('td').replaceWith(function () {
        markup = '<div class="cell large-3 medium-6 small-12">' + $(this).html() + '</div>';
        return markup;
      });
    } else if ($(this).hasClass("three")) {
      $(this).find('td').replaceWith(function () {
        var classes = $(this).attr("class");
        var styles = $(this).attr("style");

        if (typeof classes == 'undefined') { classes = ''; }
        if (typeof styles == 'undefined') { styles = ''; }

        markup = '<div class="cell large-4 medium-4 small-12 ' + classes + '" style="' + styles + '">' + $(this).html() + '</div>';
        return markup;
      });
    } else if ($(this).hasClass("two")) {
      $(this).find('td').replaceWith(function () {
        var classes = $(this).attr("class");
        var styles = $(this).attr("style");

        if (typeof classes == 'undefined') { classes = ''; }
        if (typeof styles == 'undefined') { styles = ''; }

        markup = '<div class="cell large-6 medium-6 small-12 ' + classes + '" style="' + styles + '">' + $(this).html() + '</div>';
        return markup;
      });
    } else if ($(this).hasClass("one")) {
      $(this).find('td').replaceWith(function () {
        var classes = $(this).attr("class");
        var styles = $(this).attr("style");
        if (typeof classes == 'undefined') { classes = ''; }
        if (typeof styles == 'undefined') { styles = ''; }

        markup = '<div class="cell small-12 ' + classes + '" style="' + styles + '">' + $(this).html() + '</div>';
        return markup;
      });
    }
    else {
      $(this).find('td').replaceWith(function () {
        var classes = $(this).attr("class");
        var styles = $(this).attr("style");
        if (typeof classes == 'undefined') { classes = ''; }
        if (typeof styles == 'undefined') { styles = ''; }

        markup = '<div class="cell ' + classes + '" style="' + styles + '">' + $(this).html() + '</div>';
        return markup;
      });
    }

    if ($(this).hasClass("five")) {
      $(this).find('tr').replaceWith(function () {
        rowMarkup = '<div class="grid-x grid-margin-x small-up-1 medium-up-5">' + $(this).html() + '</div>';
        return rowMarkup;
      });
      $(this).replaceWith(function () {
        wrapperMarkup = '<div class="tablegrid-wrapper ' + border_class + '">' + $(this).html() + '</div>';
        return wrapperMarkup;
      });
    }
    else {
      $(this).find('tr').replaceWith(function () {
        var classes = $(this).attr("class");
        var styles = $(this).attr("style");
        if (typeof classes == 'undefined') { classes = ''; }
        if (typeof styles == 'undefined') { styles = ''; }

        rowMarkup = '<div class="grid-x grid-margin-x ' + classes + '" style="' + styles + '">' + $(this).html() + '</div>';
        return rowMarkup;
      });
      $(this).replaceWith(function () {
        wrapperMarkup = '<div class="tablegrid-wrapper ' + border_class + '">' + $(this).html() + '</div>';
        return wrapperMarkup;
      });
    }

  });

  let addDays = (startDate, days) => {
    let date = new Date(startDate.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  let adultCount = () => parseInt($('#reservation_adult_count').val());
  let childCount = () => parseInt($('#reservation_child_count').val());

  let dateAndSlotUnavailable = (date, slotId, occupancyData) => {
    return window.blockedReservationSlots[`${date}:${slotId}`] === true ||
      (occupancyData[slotId] || 0) + (date == selectedDate && slotId == selectedSlotId ? 0 : adultCount() + childCount()) > window.maximumOccupancy;
  };

  let toggleTimeSlotAvailability = (date, occupancyData) => {
    $('#reservation_time_slot_id option').each(function () {
      if (dateAndSlotUnavailable(date, this.value, occupancyData)) {
        $(this).attr('disabled', true);
        $(this).prop('selected', false);
      } else {
        $(this).attr('disabled', false);
      }
    });
  };

  let setAvailableTimeSlots = () => {
    let selectedDate = $('.flatpickr-reservation-date').val();

    $('#reservation_time_slot_id option').attr('disabled', true);
    if ((selectedDate || "") == "") return;

    // do a first pass with the data we have
    toggleTimeSlotAvailability(selectedDate, {});

    // then query real-time occupancy data to check whether any other slots should be disabled
    fetch(`/reservation/check-occupancy/${selectedDate}`)
      .then(response => response.json())
      .then(data => toggleTimeSlotAvailability(selectedDate, data));
  };

  // Event Date Popup Flatpickr (part 1)
  if ($('.flatpickr-reservation-date').length) {
    let datepicker = flatpickr(".flatpickr-reservation-date", {
      disableMobile: "true",
      disable: window.blockedReservationDates || [],
      minDate: addDays(new Date(), 0),
      maxDate: window.furthestAllowedDate,
    });
    $(".flatpickr-calendar").attr("role", "complementary");
    $(".flatpickr-calendar").each(function (index) {
      $(this).attr("aria-label", "Date picker-" + index);
    });

    datepicker.config.onChange.push((_selectedDates, _dateStr, _instance) => {
      setAvailableTimeSlots();
    });

    // $('#reservation_time_slot_id').on('change', setAvailableTimeSlots);
    $('#reservation_adult_count').on('change', setAvailableTimeSlots);
    $('#reservation_child_count').on('change', setAvailableTimeSlots);
    setAvailableTimeSlots();
  }

  if ($('.flatpickr-hotel-checkin').length) {
    let startCal = flatpickr(".flatpickr-hotel-checkin", {
      disableMobile: "true",
      minDate: addDays(new Date(), -30)
    });
    $(".flatpickr-calendar").attr("role", "complementary");
    $(".flatpickr-calendar").each(function (index) {
      $(this).attr("aria-label", "Date picker-" + index);
    });
  }

  if ($('.flatpickr-hotel-checkout').length) {
    let startCal = flatpickr(".flatpickr-hotel-checkout", {
      disableMobile: "true",
      minDate: addDays(new Date(), 0)
    });
    $(".flatpickr-calendar").attr("role", "complementary");
    $(".flatpickr-calendar").each(function (index) {
      $(this).attr("aria-label", "Date picker-" + index);
    });
  }

});

// Event Date Popup Flatpickr (part 2)
// $(window).on("load", function () {
//   $(".flatpickr-monthDropdown-months").attr("aria-label", "Select month");

//   //Set event date to today and on
//   $("#start_date").flatpickr({
//     minDate: "today",
//     dateFormat: "d M Y"
//   });
// })
